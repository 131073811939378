<template>
  <vs-card>
    <AddDataSidebar :key="addDataKey" :addSidebarDetail="addSidebarDetail"  :isSidebarActive="addDataSidebar" ></AddDataSidebar>
    <UpdateDataSidebar :key="updateDataKey" :updateSidebarDetail="updateSidebarDetail"  ></UpdateDataSidebar>

    <div class="px-4 py-8">
      <div class="flex justify-between mb-8">
        <div>
          <h3>{{listDetail.tableHeader}}</h3>
        </div>
        <div>
          <vs-button color="success" @click="openSidebar()">
            <font-awesome-icon icon="plus" />
            เพิ่มข้อมูล
          </vs-button>
        </div>
      </div>
      <!-- <json-viewer :expand-depth="expandDepth" :value="itemList"></json-viewer> -->
      <!-- <vs-table pagination max-items="20" search class="mt-8" > -->
      <!-- <vs-table> -->
        <template >
          <vs-tr class="flex items" :key="indextr" v-for="(dat, indextr) in itemList">
            <div  class = "grid-layout-container alignment-block p-4" style = "vertical-align:text-top ;width:100%;"  >
              <vs-row vs-w="12">
                <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="10"  vs-sm="10" vs-xs="12">
                <div  class = "grid-layout-container alignment-block" style = "vertical-align:text-top ;width:100%;" >

                  <!-- แยกเป็น 2 array listDetail.listStructure -->

                    <!-- Main line -->
                    <vs-row vs-w="12"  >
                        <vs-col   vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="12"  vs-sm="12" vs-xs="12" >
                            <div :key="main_ind" v-for="(main_str, main_ind) in listDetail.listStructure[0]" class="pr-4">
                              <span v-bind:style="main_str.linkText1Style" > {{dat[main_str["dataKey"]]}} </span>
                            </div>
                        </vs-col>
                    </vs-row>

                    <!-- Sub line -->
                    <vs-row vs-w="12" class="mt-2" >
                        <vs-col   vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="12"  vs-sm="12" vs-xs="12" >
                            <div :key="sub_ind" v-for="(sub_str, sub_ind) in listDetail.listStructure[1]" class="pr-8">
                              <span v-if="sub_str.icon" >
                                <font-awesome-icon
                                    :icon="sub_str['icon']"
                                    style="color: #888"
                                    size="sm"
                                    class="cursor-pointer mr-1"
                                  />

                              </span>
                              <span style="color: #888" > {{dat[sub_str["dataKey"]]}} </span>
                            </div>
                        </vs-col>
                    </vs-row>


                </div>

            </vs-col>

            <vs-col class="pr-9 py-6" vs-type="flex" vs-justify="flex- " vs-align="center" vs-lg="2"  vs-sm="2" vs-xs="12"  >

              <div v-if="$acl.check('admin') && listDetail.tableAction.isActive === true" style="text-align: center;" class="mr-8">
                <vs-switch @change="changeState(indextr)" color="success" v-model="dat.status">
                  <span slot="on">เปิด</span>
                  <span slot="off">ปิด</span>
                </vs-switch>
              </div>

                <font-awesome-icon
                  v-if="listDetail.tableAction.isEdit === true"
                  @click="openUpdateSidebar(dat)"
                  icon="edit"
                  style="color: #5A8EE6"
                  size="lg"
                  class="cursor-pointer mr-4"
                />
                <font-awesome-icon
                  v-if="listDetail.tableAction.isDelete === true"
                  @click="deleteItem(dat)"
                  icon="trash"
                  class="text-danger cursor-pointer mr-24"
                  size="lg"
                />
            </vs-col>


          </vs-row>
          </div>
          </vs-tr>
        </template>
      <!-- </vs-table> -->

      <!-- https://www.npmjs.com/package/vue-json-viewer -->
      <!-- <span style="font-size: 2rem;">{{this.saveCount}}</span> -->

    </div>
  </vs-card>
</template>

<script>

import AddDataSidebar from "./7_2_AddDataSideBar.vue";
import UpdateDataSidebar from "./7_3_UpdateDataSideBar.vue";
import draggable from "vuedraggable";
import shapeFormat from "@/mixins/shapeFormat.js";
import JsonViewer from 'vue-json-viewer';
import Vue from "vue";

export default {
  mixins: [shapeFormat],
  props: {
    listDetail: {
      type: Object,
      required: true
    },
    addSidebarDetail: {
      type: Object
    },
    updateSidebarDetail: {
      type: Object
    },
  },
  components:{
    AddDataSidebar,
    UpdateDataSidebar,
    draggable,
    JsonViewer
  },

  async created(){

    this.allDetail = this.listDetail;

    // await this.reload();

    // console.log("created this.itemList >>> ",this.itemList);
    // console.log("created this.listDetail.reload.dataList >>> ",this.listDetail.reload.dataList);



  },
  async mounted(){
    // this.listDetail.data = this.dataItem;
    // console.log("this.addSidebarDetail >>> ",this.addSidebarDetail);
    // await this.reload();

    // this.itemList = this.$store.getters[this.listDetail.reload.dataList];
    Vue.nextTick(async () => {
      await this.reload();
      this.itemList = this.$store.getters[this.listDetail.reload.dataList];

    });


    console.log("created this.theData >>> ",this.theData);
    // console.log("created this.listDetail.reload.dataList >>> ",this.allDetail.reload.dataList);



  },

  data() {
    return {
      currentPage: 1,
      pageSize: 1,
      expandDepth: 4,
      modalActive: false,
      addDataKey: 0,
      updateDataKey: 0,
      httpBodyReq: {},
      theData : [],

      allDetail: {},

     saveCount: 0,


    };
  }
  ,
  computed:{
    itemList() {
    //   Vue.nextTick(async () => {
    //   await this.reload();
    //   this.theData = this.$store.getters[this.listDetail.reload.dataList];

    // });
      // console.log("the computed ITEM LISt " );
      return this.$store.getters[this.listDetail.reload.dataList];
      // return this.$store.state.duration.items;
    },
    // itemList: {
    //     set(){
    //       console.log("SET SET " );
    //       this.theData = this.$store.getters[this.listDetail.reload.dataList];

    //     },
    //     get(){
    //       console.log("this.$store.getters[this.listDetail.reload.dataList] >>> ",this.$store.getters[this.allDetail.reload.dataList])
    //       return this.$store.getters[this.listDetail.reload.dataList];
    //     }
    //   },
    addDataSidebar:{
      set(){

      },
      get(){
        return this.$store.getters["sidebar/addDurationSideBar"]
      }
    },
  updateDataSidebar:{
      set(){

      },
      get(){
        return this.$store.getters["sidebar/updateDurationSideBar"]
      }
    }
  }
  ,

  methods: {
    openSidebar(){
      this.$store.commit("sidebar/addDurationSideBarToggle",true);
    },

    // openUpdateSidebar(){
    //   this.$store.commit("sidebar/updateDurationSideBarToggle",true);
    // },
    async reload(){

                //*** table filter
                // this.prepareFilter();


                //*** add pagination
                if(this.listDetail.page !== null && this.listDetail.page !== undefined && this.listDetail.page.show === true )
                {
                  this.httpBodyReq["page"] = this.currentPage;
                  this.httpBodyReq["pageSize"] = this.pageSize;
                }



                let params = {
                  apiUrl: this.listDetail.reload.apiUrl,
                  params: this.httpBodyReq,
                  useStore : this.listDetail.useStore
                };

                console.log("this.listDetail.reload.storeAction >>> ", this.listDetail.reload.storeAction)
                console.log("params >>> ",params)

                await this.$store.dispatch(this.listDetail.reload.storeAction, params);
                // this.data = store.getters["table/items"];

                // this.changeSelectedList()

    },

    async openUpdateSidebar(item){

      let storeName = this.listDetail.useStore;
      let itemIdName = this.$store.getters[storeName + "/itemId"]
      console.log(" updateSidebar (*_)(&*& )",item[itemIdName]);

      this.$store.commit(storeName + "/UPDATE_CURRENT_ID", item[itemIdName]);

      this.$store.commit("sidebar/updatedDurationSideBarToggle",true);

      console.log(" this.$store.state.duration.currentId (*_)(&*& )",this.$store.state.duration.currentId);

    },

    async deleteItem(item){

      let storeName = this.listDetail.useStore;
      let itemIdName = this.$store.getters[storeName + "/itemId"]

      let apiParam = {};
      apiParam[itemIdName] = item[itemIdName];

      // console.log(" deleteItem >>> apiParam ",apiParam);

      let param = {
          item : item,
          apiParam: apiParam,
          formDetail : this.listDetail.tableAction.deleteAction

      };

      await this.$store.dispatch(this.listDetail.tableAction.deleteAction.storeAction, param);
    },
  },

  watch:{
    saveCount(){
          if(this.saveCount == 0)
          {
            // this.$swal({
            //       icon: "info",
            //       title: "SAVED"
            //     });
          }
    },

    listDetail: {
      handler(){
        this.saveCount = this.saveCount + 1;
        setTimeout(() => {
          this.saveCount = this.saveCount - 1;
        }, 2000);
      },
      deep: true
    },
    // itemList:{
    //          handler(value){



    //             console.log("this.listDetail.useStore >>> ",value);


    //           },
    //           deep:true
    //       },

},

};
</script>

<style>
.con-vs-popup .vs-popup {
  width: 100% !important;
}

.vs-popup {
  width: 100% !important;
}
</style>
