
<template lang="pug">
.grid-layout-container.alignment-block.p-8(style="vertical-align:text-top ;width:100%;padding-bottom:40px;")
  .row
    div(v-for="(formParam, fi) in formDetail.formGroup", v-bind:key="fi")
      //- TEXT TYPE
      .form-group.pt-4(v-if="formParam['type'] === 'text' || formParam['type'] === 'email'", :style="formParam['style']")
        label {{formParam['label']}}
          span(v-if="formParam['required']===true", style="color:red;")  *
        //- b-form-input(:type="formParam['type']", :name="formParam['param']", :placeholder="formParam['placeholder']", v-model="$data['form1'][fi]", :state="validation[fi]", aria-describedby="input-shopName-help", debounce="500", @change="validate_text(fi ,formParam,$data['form1'][fi])")
        vs-input.w-full( :type="formParam['type']", :name="formParam['param']", :placeholder="formParam['placeholder']", v-model="$data['form1'][fi]",  )
        span.text-muted.font-weight-bold.text-muted.d-block.pt-3(v-if="formParam['subText']") {{item[formParam['subText']]}}
        span.text-muted.font-weight-bold.text-muted.pr-3.pt-3(v-if="formParam['bluePrefix'] && formParam['blueText1']==='length'", style="color: #2372ce !important; text-weight: bold;") {{formParam['bluePrefix']}}

          | {{smsTextLength($data['form1'][fi])}}
        span.text-muted.font-weight-bold.text-muted.pt-3(v-if="formParam['redPrefix'] && formParam['redText1']==='credit'", style="color: #f64e60 !important; text-weight: bold;") {{formParam['redPrefix']}}

          | {{calculateSmsCredit($data['form1'][fi])}}
          span.text-muted.font-weight-bold.text-muted.pr-3.pt-3 {{warning[fi]}}



      .form-group.pt-4(v-if="formParam['type'] === 'number'", :style="formParam['style']")
        label {{formParam['label']}}
          span(v-if="formParam['required']===true", style="color:red;")  *
        vs-input.w-full(:type="formParam['type']", :name="formParam['param']", :placeholder="formParam['placeholder']", :state="validation[fi]", v-model="$data['form1'][fi]", @change="validate_number(fi, formParam ,$data['form1'][fi])")
        //- span.text-muted.font-weight-bold.text-muted.pr-3.pt-3(:id="formParam['param']") กรุณาใส่ข้อมูล

  vs-row.pt-20
    vs-col(v-for="(formBtn, bi) in formDetail.formButton", v-bind:key="bi", vs-type='flex', vs-justify='end', vs-align='center', vs-lg='6', vs-sm='6', vs-xs='12')
      vs-button(v-if="formBtn.type === 'clear'", type="border" color="danger" , v-on:click="clearData($event)", style="width:80%") ยกเลิก
      vs-button(v-if="formBtn.type === 'submit' || formBtn.type === 'uploadFile'", v-on:click="submitData($event,formBtn)" , style="width:80%") บันทึก

    //- .d-flex.justify-content-end.pt-4
    //- div(v-for="(formBtn, bi) in formButton", v-bind:key="bi")

          //- b-button.btn.text-uppercase.px-9.py-4.ml-8(v-if="formBtn.type === 'storeActionAsync'", v-on:click="storeAction($event,formBtn)", :variant="concatText('outline-',formBtn.color)", style="font-family: mitr; font-weight: bold; font-size: 1.1rem;", data-wizard-type="action-submit", :style="formBtn.style", :disabled="buttonLock") {{formBtn.label}}
          //- b-button.btn.text-uppercase.px-9.py-4.ml-8(v-if="formBtn.type === 'submit' || formBtn.type === 'uploadFile'", v-on:click="submitData($event,formBtn)", :variant="concatText('outline-',formBtn.color)", style="font-family: mitr; font-weight: bold; font-size: 1.1rem;", data-wizard-type="action-submit", :style="formBtn.style", :disabled="buttonLock") {{formBtn.label}}
          //- b-button.btn.text-uppercase.px-9.py-4.ml-8(v-if="formBtn.type === 'clear'", v-on:click="clearData($event)", :variant="concatText('outline-',formBtn.color)", style="font-family: mitr; font-weight: bold; font-size: 1.1rem;", data-wizard-type="action-submit", :style="formBtn.style") {{formBtn.label}}



</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import shapeFormat from "@/mixins/shapeFormat.js";
import Multiselect from "vue-multiselect";
import vSelect from "vue-select";
import JsonViewer from 'vue-json-viewer';
import Vue from 'vue';
import Swal from "sweetalert2";

// import {
//   convertToArray
// } from '@/utils'


export default {
mixins: [shapeFormat],
props: {
  formDetail: {
    type: Object,
    required: true
  },

  components: {
  VuePerfectScrollbar,
  Multiselect,
  "v-select": vSelect,
  JsonViewer

},
  data: {
    type: Object,
    default: () => {}
  },
  itemIndex: {
    type: Number
  }
},
watch: {

},
data() {
  return {

    allFormData: null,
      form1: [],
      validation: [],
      warning: [],



  };
},
async created(){
  console.log("0.1 this.formDetail >>> ",this.formDetail);

},

mounted() {


      let storeName = this.formDetail.useStore;
      // let itemIdName = this.$store.getters[storeName + "/itemId"];
      let id = this.$store.getters[storeName + "/currentId"];

        // let id = this.formDetail.formDataId;
        console.log("this.formDetail.useStore >>> ",this.formDetail.useStore);
        console.log("itemIdName >>> ",storeName + "/currentId");
        console.log(" this.$store.getters[storeName + /currentId] ", this.$store.state.duration.currentId);
        if(this.formDetail.formType === "update"){
          this.mappingForDataToForm1(id);
        }else{
          this.init();
        }
        // console.log("MOUNTED formDetail >>>", formDetail);

    this.init();
  },


computed: {

},
methods: {

  init(){

        this.allFormData = new FormData();

        this.formDetail.formGroup.forEach((item , ind) => {

        //*** INITIAL VALIDATION */
        this.validation[ind] = null;
        this.warning[ind] = null;

        //*** INITIAL VARIABLE each  */

        if(item["type"] === "text"){
          this.form1[ind]  = null;
        }else if(item["type"] === "tag"){
            this.form1[ind] = [];
        }else if(item["type"] === "number"){
          this.form1[ind] = null;
        }else if(item["type"] === "datetime"){
          this.form1[ind] = null;
        }else if (item["type"] === "select"){
          this.form1[ind] = null;
        }
        else if (item["type"] === "uploadbutton"){
          this.form1[ind] = null;
        }
        else if (item["type"] === "uploadDefaultImage"){
          this.form1[ind] = null;
        }
        else if (item["type"] === "textarea"){
          this.form1[ind] = null;
        } else if (item["type"] === "file"){
          this.form1[ind] = null;
        }

        else if (item["type"] === "hidden"){
          this.form1[ind] = null;
        }


        else{
          this.form1[ind] = null;
        }

        // this.mappingForDataToForm1(ind);
        });
    },


    async mappingForDataToForm1(id){
      if(this.formDetail.formType === "update"){

              console.log("UmappingForDataToForm1 id >>> a",id);

              let param = {};
              param.apiUrl = this.formDetail.formApiUrl;
              param.params = id;

            await this.$store.dispatch(this.formDetail.formStore + "/loadFormData" , param);

            console.log("mappingForDataToForm1 UPDATE CASE formdata", this.formData);

            this.formDetail.formGroup.forEach((item , ind) => {
                this.$data["form1"][ind]  =  this.formData[item.param];
                Vue.set(this.$data["form1"] , ind , this.formData[item.param]);
            });
        }
    },


clearData(e){

        e.preventDefault();

        console.log(">>>>>>>>>><<<<<<<<<<<<");

        this.allFormData = new FormData();

        this.formDetail.formGroup.forEach((item , ind) => {

        //*** INITIAL VALIDATION */
        this.validation[ind] = null;
        this.warning[ind] = null;

        //*** INITIAL VARIABLE each  */

        if(item["type"] === "text"){
          // this.form1[ind]  = null;
          Vue.set(this.$data.form1, ind, null);

        }else if(item["type"] === "number"){
          // this.form1[ind] = 0;
          Vue.set(this.$data.form1, ind, null);

        }else if(item["type"] === "datetime"){

          // this.form1[ind] = null;
          Vue.set(this.$data.form1, ind, null);


        }else if (item["type"] === "select"){
          // this.form1[ind] = null;
          Vue.set(this.$data.form1, ind, null);
        }
        else if (item["type"] === "uploadbutton"){
          // this.form1[ind] = null;
          Vue.set(this.$data.form1, ind, null);
        }
        else if (item["type"] === "uploadDefaultImage"){
          this.form1[ind] = null;
        }
        else if (item["type"] === "textarea"){
          // this.form1[ind] = null;
          Vue.set(this.$data.form1, ind, null);
        } else if (item["type"] === "file"){
          // this.form1[ind] = null;
          Vue.set(this.$data.form1, ind, null);
        }

        else if (item["type"] === "hidden"){
          // this.form1[ind] = null;
          Vue.set(this.$data.form1, ind, null);
        }

        else{
          // this.form1[ind] = null;
          Vue.set(this.$data.form1, ind, null);
        }

        });

        this.$store.commit("sidebar/allSidebarClose");
},

async submitData(e, formBtn) {
      e.preventDefault();
      // this.$loading.show();

      console.log("0.1 submitData() >>> ",this.formDetail)

      var checkState = true;

      let httpRequest = {};

      let startDate = null;
      let endDate = null;
      let startDateTime = null;
      let endDateTime = null;

      this.formDetail.formGroup.forEach((formParam , fi) => {
            //*** INITIAL VALIDATION */
            // this.validation[ind] = null;

            //*** HANDLE HIDDEN BEGIN: */

            let prmArr = [];


            if(formParam["type"] === "hiddenText" || formParam["type"] === "hiddenNumber" ){

              prmArr =  this.extractValue(this.$data['form1'], this.formDetail.formGroup , formParam["functParam"]);
              console.log("formParam >>> ",formParam);
              console.log("prmArr >>> ",prmArr);

              if(formParam["registry"] === "shapeFormat")
              {
                Vue.set( this.$data['form1'], fi , this[formParam["funct"]](prmArr)  );
              }

            }


            //*** HANDLE HIDDEN END: */


            //*** INITIAL VARIABLE each  */

            if(formParam["type"] === "text" || formParam["type"] === "email" || formParam["type"] === "hiddenText"){
              //
              this.validate_text(fi ,formParam, this.$data['form1'][fi]);

              //*** Mapping data
              httpRequest[formParam["param"]] = this.$data['form1'][fi];


            }
            else if(formParam["type"] === "tag" ){

              this.validate_number(fi ,formParam , this.$data['form1'][fi]);

              //*** Mapping data
              httpRequest[formParam["param"]] = this.$data['form1'][fi];
            }
            else if(formParam["type"] === "number" || formParam["type"] === "hiddenNumber"){

              this.validate_number(fi ,formParam , this.$data['form1'][fi]);

              //*** Mapping data
              httpRequest[formParam["param"]] = this.$data['form1'][fi];
            }
            else if(formParam["type"] === "textarea"){

              this.validate_text(fi ,formParam, this.$data['form1'][fi]);

              //*** Mapping data
              httpRequest[formParam["param"]] = this.$data['form1'][fi];

            }
            else if(formParam["type"] === "datetime"){

              this.validate_date(fi, formParam['required'] ,this.$data['form1'][fi]);

              //*** Mapping data
              httpRequest[formParam["param"]] = this.formatDate(this.$data['form1'][fi]);

            }else if (formParam["type"] === "select"){

              this.validate_select(fi, formParam['required'] ,this.$data['form1'][fi]);

              //*** Mapping data
              httpRequest[formParam["param"]] = this.$data['form1'][fi];

            }else if (formParam["type"] === "uploadbutton" || formParam["type"] === "uploadDefaultImage"){
              this.validate_uploadbutton(fi, formParam['required'] ,this.$data['form1'][fi]);

              //*** Mapping data
              httpRequest[formParam["param"]] = this.$data['form1'][fi];

            }


            else{

                this.validate_text(fi ,formParam, this.$data['form1'][fi]);

                httpRequest[formParam["param"]] = this.$data['form1'][fi];
            }

            //*** MAPPING DATA TO API */



            if ( formParam["param"] === "startDate" ) startDate = this.$data['form1'][fi];
            if ( formParam["param"] === "endDate" ) endDate = this.$data['form1'][fi];
            if ( formParam["param"] === "startDateTime" ) startDateTime = this.$data['form1'][fi];
            if ( formParam["param"] === "endDateTime" ) endDateTime = this.$data['form1'][fi];


      });



      console.log("httpRequest >>> ",httpRequest);

      if ( startDate !== null && endDate !== null ) {
        if (this.formatDate(startDate) > this.formatDate(endDate)) {
          Swal.fire({
              title: "",
              text: `วันสิ้นสุดต้องไม่เกินวันเริ่มต้น`,
              icon: "warning",
              confirmButtonClass: "btn btn-secondary"
            });

          return;
        }
      }

      if ( startDateTime !== null && endDateTime !== null ) {
        if (this.formatDate(startDateTime) > this.formatDate(endDateTime)) {
          Swal.fire({
              title: "",
              text: `วันสิ้นสุดต้องไม่เกินวันเริ่มต้น`,
              icon: "warning",
              confirmButtonClass: "btn btn-secondary"
            });

          return;
        }
      }

      this.validation.forEach( item =>
        {
          // console.log("validation item >>> ",item);
          if(item === false) checkState = false;
          // checkState = checkState && item
        }
      );


      // this.$store.commit("common/OVERLAY",true);


      if(checkState === false){
          this.disableBtn = false;
          // Swal.fire({
          //   title: "",
          //   text: "ยังใส่ข้อมูลไม่ครบถ้วน",
          //   icon: "warning",
          //   confirmButtonClass: "btn btn-secondary"
          // });
            this.$vs.notify({
                title: 'ตรวจสอบข้อมูล',
                text: 'ยังใส่ข้อมูลไม่ครบถ้วน',
                color: 'warning',
                iconPack: 'feather',
                position: 'top-right',
                icon: 'icon-alert-circle'
            });

          httpRequest = {};

          return;
      }

      this.disableBtn = true;

      this.$store.commit("common/BUTTON_LOCK");

      let data = {};
      data.apiUrl = formBtn.apiUrl;
      data.params = httpRequest;
      data.formDetail = formBtn;

      // console.log("MAPPING DATA TO API BEFORE SEND TO STORE >>> ",httpRequest);

      if(formBtn.type === "submit"){
         if(this.formDetail.formType === "update"){
            data.dataId = this.formDetail.formDataId;
            await this.$store.dispatch(formBtn.storeAction, data);
         }else{
           //*** สร้างใหม่
           console.log("*** สร้างใหม่ >>> ",data);
            await this.$store.dispatch(formBtn.storeAction, data);
            this.form1 = [];
            this.validation = [];
            this.warning= [];
         }



      }
      else if(formBtn.type === "uploadFile"){
        await this.uploadFileFunc(formBtn)
      }

      // this.$store.commit("common/OVERLAY",false);

    //  this.$loading.hide();


    },


  validate_text(ind, form, text){

      Vue.set(this.warning, ind , null);

      if( (text === null || text === "" || text === undefined) && form["required"] === true )
      {
        // console.log("--------- 1 ----------");
        Vue.set(this.validation, ind , false);
        Vue.set(this.warning, ind , "กรุณาใส่ข้อมูล");
        return ;
      }

      if( (text === null || text === "" || text === undefined) && (form["required"] === false || form["required"] === null || form["required"] === undefined) )
      {
        // console.log("--------- 2 ----------");
        Vue.set(this.validation, ind , null);
        return ;
      }

      if( text.length <= 0 && form["required"] === true )
      {
        //  console.log("--------- 3 ----------");
          Vue.set(this.validation, ind , false);
          Vue.set(this.warning, ind , null);
          return;
      }

        //*** ถ้ามีการกำหนด form["min"] */
        if(form["min"] != null && form["min"] != undefined){
            if(text.length < form["min"] ){
                // console.log("--------- 4 ----------");
                Vue.set(this.validation, ind , false);
                Vue.set(this.warning, ind , "ใส่ตัวอักษรไม่น้อยกว่า " +  form["min"]);
                return;
            }
        }

        //*** ถ้ามีการกำหนด form["max"] */
        if(form["max"] != null && form["max"] != undefined){
          //*** ถ้าความยาว text มากกว่า max */
          if(text.length > form["max"] ){
                // console.log("--------- 5 ----------");
                Vue.set(this.validation, ind , false);
                Vue.set(this.warning, ind , "ใส่ตัวอักษรไม่เกิน " +  form["max"]);
                return;
            }
        }


        if(form["type"] === "email"){
              let res = this.validate_email(text);
              if(res === true){
                Vue.set(this.validation, ind , true);
                return;
              }else{
                Vue.set(this.warning, ind , "ใส่ข้อมูลในรูปแบบตัวอักษร ตัวอย่าง user@example.com");
                Vue.set(this.validation, ind , false);
                return;
              }
        }


        // console.log("--------- 6 ----------");
        Vue.set(this.validation, ind , true);

    }, // validate Text

    validate_number(ind, form,  item){

// console.log("validate_number>>> ",ind , " form: ", form , " item : ", item  );
// console.log("this.$data['form1'] >>> ",this.$data['form1'] );

if(  (item === null || item === undefined || item === "")   && form["required"] === true  )
{
  // console.log("--------- 1 ------------- ");

  Vue.set(this.validation, ind , false);
  return ;
}

if(  (item === null || item === undefined || item === "")  && (form["required"] === false || form["required"] === null || form["required"] === undefined) )
{
  // console.log("--------- 2 ------------- ");
  Vue.set(this.validation, ind , null);
  return ;
}

if(form["positiveOnly"] === true && item < 0 ){
  // console.log("--------- 4 ------------- ");
   Vue.set(this.$data['form1'] , ind , 0);
   console.log("positiveOnly >>> " )
   Vue.set(this.validation, ind , false);
   return ;
}


if(item !== null && item !== "" && item !== undefined){
  // console.log("--------- 3 ------------- ");
    Vue.set(this.validation, ind , true)
    return ;
}


},



},

};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.add-new-data-sidebar {
::v-deep .vs-sidebar--background {
  z-index: 52010;
}

::v-deep .vs-sidebar {
  z-index: 52010;
  width: 400px;
  max-width: 90vw;

  .img-upload {
    margin-top: 2rem;

    .con-img-upload {
      padding: 0;
    }

    .con-input-upload {
      width: 100%;
      margin: 0;
    }
  }
}
}

.scroll-area--data-list-add-new {
// height: calc(var(--vh, 1vh) * 100 - 4.3rem);
height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
