
<template>
  <div>
    <GenericList :addSidebarDetail="addSidebarDetail" :updateSidebarDetail="updateSidebarDetail" :listDetail="listDetail"  ></GenericList>
    <LoadingTable></LoadingTable>

  </div>
</template>

<script>
import GenericList from "./GenericList.vue";
import shapeFormat from "@/mixins/shapeFormat.js";
import LoadingTable from "../Components/LoadingTable.vue";
export default {
  mixins: [shapeFormat],

  data() {
    return {

    addSidebarDetail: {
      formHeader: "เพิ่มจำนวนวัน",
      formSubHeader: "",
      useStore: "duration",
      formButton:[
            {
            label: "Clear",
            color: "warning",
            style: {'width': '120px'},
            type: "clear",
            // apiUrl : "/v1/file/upload-excel",
            // storeAction: "coupon/addDataUnshift",
            // apiParam: "uid"
          },
          {
            label: "บันทึก",
            formType : "create",
            color: "success",
            style: {'width': '150px'},
            action: "submit",
            type:"submit",
            apiUrl :"/api/v2/setting/rent-duration/add",
            storeAction: "common/submitData",
            nextAction: "callasync", //*** {redirect , callasync, call} */
            callAction: "common/loadData",
            callApi: "/api/v2/setting/rent-duration/list",
            callParams: {},
            callUseStore: "duration",
            // apiParam: "uid"
          }
          ],
      formGroup:  [
            {
               type: "number",
                param: "rentDays",
                required: true,
                label: "จำนวนวัน",
                width: "full" , //*** {full, half}
                placeholder: "ex. 1, 2, 3",
                // icon: ["flaticon2-search-1", "text-muted"],
              },
        ]
    },

    updateSidebarDetail: {
      formHeader: "อัพเดตข้อมูลจำนวนวัน",
      formSubHeader: "",
      useStore: "duration",
      formButton:[
            {
            label: "Clear",
            color: "warning",
            style: {'width': '120px'},
            type: "clear",
            // apiUrl : "/v1/file/upload-excel",
            // storeAction: "coupon/addDataUnshift",
            // apiParam: "uid"
          },
          {
            label: "บันทึก",

            formType : "update", //** required
            color: "success",
            style: {'width': '150px'},
            action: "submit",
            type:"submit",
            apiUrl :"/api/v2/setting/rent-duration/update",
            storeAction: "common/submitData",
            nextAction: "callasync", //*** {redirect , callasync, call} */
            callAction: "common/loadData",
            callApi: "/api/v2/setting/rent-duration/list",
            callParams: {},
            callUseStore: "duration",
            // apiParam: "uid"
          }
          ],
      formGroup:  [
            {
               type: "number",
                param: "rentDays",
                required: true,
                label: "aaaa",
                width: "full" , //*** {full, half}
                placeholder: "ex. 1, 2, 3",
                // icon: ["flaticon2-search-1", "text-muted"],
              },
        ]

    },


    listDetail: {
           tableHeader: "ตัวเลือกการแบ่งราคาสินค้า",
           // tableSubHeader: "ข้อมูลทั้งหมด",

          //  page:{
          //    show: false,
          //    topbottom: 1, //*** {1: show only bottom, 2: show only top, 3: show both }
          //    pageSize: 20,
          //    pageOption: [20,50,100,200,500],
          //  },



           useStore: "duration",
          //   itemSelection: {
          //    show : false,
          //    store: "campaign",
          //    items : "campaign/selected",
          //  },
           tableAction: {
             isDelete : true,
             deleteAction:{
              apiUrl :"/api/v2/setting/rent-duration/delete",
              storeAction: "common/postNeedConfirm",
              nextAction: "callasync", //*** {redirect , callasync, call} */
              callAction: "common/loadData",
              callApi: "/api/v2/setting/rent-duration/list",
              callParams: {},
              callUseStore: "duration",
              confirmText: "โปรดตรวจสอบข้อมูลก่อนทำการลบ",
              confirmButtonText: 'ตรวจสอบแล้ว ต้องการลบ',

             },
             isEdit : true,
             editAction:{

              },
            //  isActive : true,


           },
          //  // tableClass : ['px-40'],
           reload: {
             apiUrl: "/api/v2/setting/rent-duration/list",
             storeAction: "common/loadData",
             dataList : "duration/items",
           },


          //  //*** รูปบบข้อมูลที่อยู่แต่ละคอลัมน์ตาราง

          //  filterButton: {
          //    label : "search",
          //    color: "danger",
          //    apiUrl: "/ecoupon-api/ecoupon/campaign-list/".concat(this.$route.params.brandId),
          //    storeAction: "campaign/loadData",
          //    // dataList : "brand/items",
          //  },
          //  filterClear: {
          //    label : "clear",
          //    color: "warning",
          //    // apiUrl: "/ecoupon-api/ecoupon/brand-list",
          //    // storeAction: "brand/loadData",
          //    // dataList : "brand/items",
          //  },


          //  //*** ปุ่มบนตารางเพื่อจะ redirect หรือทำบางอย่าง e.g. export excel
          //  tableButton:  [
          //    // {
          //    //   text : "Add Campaign",
          //    //   color : "success",
          //    //   storeAction: "campaign/add",
          //    //   apiName: "filter-coupon",
          //    // },

          //  ],

          //  tableComponent:  [
          //    // {
          //    //   component: sidebarImport,
          //    //   detail: {
          //    //     componentId : "addCampaign",
          //    //     componentMessage : "Add Campaign",
          //    //     iconClass: ["fa", "fa-plus-circle", "icon-md"],
          //    //     content : [
          //    //       {
          //    //         type : "form",
          //    //         detail: {},
          //    //       }
          //    //     ]
          //    //   },
          //    // },

          //  ],


          //  apiName: "filter-coupon",
          //  tableFilter:  [
          //    {
          //      type: "text",
          //      param: "searchText",
          //      mapTo: "t1",
          //      dataType: "string",
          //      placeholder: "search",
          //      icon: ["flaticon2-search-1", "text-muted"],
          //    },




          //  ],

           //*** รูปบบข้อมูลที่อยู่แต่ละคอลัมน์ตาราง
           listStructure:  [
            //  {
            //    columnName: "รูป", //** ชื่อ columns ที่จะปรากฏในตาราง */
            //    style:  {'width': '150px'},
            //    type: "image", //*** {image, text, status , date , progress, link, number} */
            //    mainText: "dataImage",

            //  },
            [

                //*** บรรทัดที่ 1

                {
                  //  width:  {'width': '30%'},
                  type: "mainText", //*** {image, text, status , date , progress, link, number} */
                  //  mainBlueText:  "sec",
                  dataKey: "rentDayStr",
                  linkText1Style: {'font-size':'1.5rem', 'font-weight': 'bold'},
                  // subText2: "text4",
                },
                //*** บรรทัดที่ 1
                {
                  //  width:  {'width': '30%'},
                  type: "mainText", //*** {image, text, status , date , progress, link, number} */
                  //  mainBlueText:  "duration",
                  dataKey: "duration",
                  linkText1Style: {'font-size':'1.5rem' , 'font-weight': 'bold'},
                  // subText2: "text4",
                },
            ],
            [
                  //*** Footer 1
                  {
                    type: "footer", //*** {image, text, status , date , progress, link, number} */
                    icon: "user",
                    dataKey: "updateBy",

                  },

                  //*** Footer 1
                  {
                    type: "footer", //*** {image, text, status , date , progress, link, number} */
                    icon: "calendar",
                    dataKey: "updateDate",

                  },
              ]



           ] // end: tableStructure
           ,
           data: [
          //  {
          //         // sec : 1,
          //         duration: "3 วัน",
          //         createDateTime :  this.formatDate(new Date("2022-11-09")),
          //         createBy :  "staff@alexrental.app",
          //         state: true,
          //         durationId: "x0001"

          //       },
          //       {
          //         // sec : 2,
          //         duration: "5 วัน",
          //         createDateTime :  this.formatDate(new Date("2022-11-10")),
          //         createBy :  "staff@alexrental.app",
          //         state: true,
          //         durationId: "x0002"

          //       },
          //       {
          //         // sec : 3,
          //         duration: "7 วัน",
          //         createDateTime :  this.formatDate(new Date("2022-11-11")),
          //         createBy :  "staff@alexrental.app",
          //         state: true,
          //         durationId: "x0003"

          //       },
           ],

     },


    };
  },
  components: {
    GenericList,
    LoadingTable
  }
};
</script>

