
<template lang="pug">
  vs-sidebar.add-new-data-sidebar.items-no-padding(click-not-close="" position-right="" parent="body" default-index="1" color="primary" spacer="" v-model="isSidebarActiveLocal")
    // หัวเรื่อง
    .mt-6.flex.items-center.justify-between.px-6
      h4
        | {{ this.addSidebarDetail.formHeader }}
        //- | {{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} ITEM
      feather-icon.cursor-pointer(icon="XIcon" @click.stop="colseSidebar")
    // เส้นแบ่ง
    vs-divider.mb-0

    // VuePerfectScroll
    vue-perfect-scrollbar.scroll-area--data-list-add-new(:settings="settings" :key="$vs.rtl")

      // FORM ใส่ข้อมูล
      AbstractFormDetail(:formDetail = "addSidebarDetail")
    // **************** SUBMIT ****************
    //- .flex.flex-wrap.items-center.p-6(slot="footer")
    //-   vs-button.mr-6(@click="submitData" ) บันทึก
    //-   vs-button(type="border" color="danger" @click="isSidebarActiveLocal = false") ยกเลิก


</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import shapeFormat from "@/mixins/shapeFormat.js";
import Multiselect from "vue-multiselect";
import vSelect from "vue-select";
import  AbstractFormDetail from  "@/views/wintershopback/Configurations/0_1_Abstract_FormDetail.vue";

// import {
//   convertToArray
// } from '@/utils'


export default {
mixins: [shapeFormat],
props: {
  addSidebarDetail:{
    type: Object,
    default: () => {}
  },

  isSidebarActive: {
    type: Boolean,
    // required: true
  },
  data: {
    type: Object,
    default: () => {}
  },
  itemIndex: {
    type: Number
  }
},
watch: {




},
data() {
  return {
    loadedBranch: [],
    itemAmount: 0,
    minimumRemaining: 0,
    remainingItems: 0,
    assignItemPanelActive: false,
    pg : {},
    webItem:false,
    hotItem: false,
    newArrival: false,
    recommended: false,
    webcat_value: [],
    allWebcat: [],
    category_choices: [
      { text: "Audio", value: "audio" },
      { text: "Computers", value: "computers" },
      { text: "Fitness", value: "fitness" },
      { text: "Appliance", value: "appliance" }
    ],

    order_status_choices: [
      { text: "Pending", value: "pending" },
      { text: "Canceled", value: "canceled" },
      { text: "Delivered", value: "delivered" },
      { text: "On Hold", value: "on_hold" }
    ],
    settings: {
      // perfectscrollbar settings
      maxScrollbarLength: 60,
      wheelSpeed: 0.6
    },

  };
},

async created(){
  console.log("@@@ 7.2 addSidebarDetail >>> ",this.addSidebarDetail);

},


computed: {

  isSidebarActiveLocal: {
    get(){
      return this.$store.getters["sidebar/addDurationSideBar"];
    },
    set() {

    }
  },
  isFormValid() {
    return (
      !this.errors.any() &&
      this.dataName &&
      this.dataCategory &&
      this.dataPrice > 0
    );
  }
},
methods: {
  // async getAllWebcat() {
  //   var allcate = await this.getOne("/api/webcategory");
  //   this.allWebcat =  convertToArray(allcate, "description");

  // },
  initValues() {

  },

  colseSidebar(){
    this.$store.commit("sidebar/addDurationSideBarToggle");
  },
  async submitData() {

    var resp = await this.saveStock();

    if(resp !== null && resp !== undefined )
    {
      this.$emit("closeSidebar");
      this.$emit("returnInStock", this.data.inStock, this.itemIndex)


    }

  },


},
components: {
  VuePerfectScrollbar,
  Multiselect,
  "v-select": vSelect,
  AbstractFormDetail

}
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.add-new-data-sidebar {
::v-deep .vs-sidebar--background {
  z-index: 52010;
}

::v-deep .vs-sidebar {
  z-index: 52010;
  width: 400px;
  max-width: 90vw;

  .img-upload {
    margin-top: 2rem;

    .con-img-upload {
      padding: 0;
    }

    .con-input-upload {
      width: 100%;
      margin: 0;
    }
  }
}
}

.scroll-area--data-list-add-new {
// height: calc(var(--vh, 1vh) * 100 - 4.3rem);
height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
